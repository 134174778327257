import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import {FallbackView} from '../../_metronic/partials'
const AccessPermissions = lazy(() => import('../pages/access-permissions/accessPermissions'))
const Users = lazy(() => import('../pages/users/users'))
const EditProfile = lazy(() => import('../pages/profile/editProfile'))
const Locations =  lazy(() => import('../pages/location/locations'))
const AddEditLocations =  lazy(() => import('../pages/location/location'));
const NewHire = lazy(() => import('../pages/new-hire/newHire'))
const EditUser = lazy(() => import('../pages/new-hire/editUser'))
const sheetWrapper = lazy(() => import('../pages/sheet/sheetWrapper'))
const ListAdmin = lazy(() => import('../pages/admin/listAdmin'))
const addAdmin = lazy(() => import('../pages/admin/addAdmin'));

export function PrivateRoutes() {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route path='/editor' component={sheetWrapper} />
        <Route path='/access-permission/:userId' component={AccessPermissions} />
        <Route path='/new-hire' component={NewHire} />
        <Route path='/edit-user' component={EditUser} />
        <Route path='/users' component={Users} />
        <Route path='/edit-profile' component={EditProfile} />
        <Route path='/all-locations' component={Locations} />
        <Route path='/add-location' component={AddEditLocations} />
        <Route path='/edit-location' component={AddEditLocations} />
        <Route path='/admin-users' component={ListAdmin} />
        <Route path='/add-admin' component={addAdmin} />
        <Route path='/edit-admin' component={addAdmin} />
        <Redirect from='/auth' to='/editor' />
        <Redirect exact from='/' to='/editor' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
