/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import axios from 'axios'
import Swal from 'sweetalert2'
// import {toast} from 'react-toastify';
const ENDPOINT = process.env.REACT_APP_API_URL || 'http://localhost:5050/'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
  isAdmin: false,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([])
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch()
  const getLocations = async () => {
    const locations: any = await axios.get(`${ENDPOINT}/locations`)
    setLocations(locations.data.data)
  }
  // useEffect(() => {
  //   getLocations()
  // }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // setTimeout(() => {
      const body = {
        email: values.email,
        password: values.password,
      }
      axios.post(`${ENDPOINT}/auth/login`, body).then((data) => {
        if (data?.data?.success) {
          // socketConnection.connect(() => {})
          localStorage.setItem('accessToken', data.data.accessToken)
          localStorage.setItem('refreshToken', data.data.refreshToken)
          // localStorage.setItem('locationId', values.location)
          // setUser(login.data)
          setLoading(false)
          setSubmitting(false)
          dispatch(auth.actions.login(data?.data?.accessToken))
          // dispatch(auth.actions.setLocation(values.location))
          // store.dispatch(userSlice.actions.setUserState(login.data));
          localStorage.setItem('user', JSON.stringify(data.data.data))
          // navigate(`/spreadsheet/${makeid(12)}`)
        } else {
          // alert error
          setLoading(false)
          setSubmitting(false)
          // toast(`${login.data.message}` || 'Unable to login');
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: `${data?.data?.message}` || 'Unable to login',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
      // login(values.email, values.password)
      //   .then(({data}) => {
      //     console.log('data',data);
      //     setLoading(false)
      //     // dispatch(auth.actions.login(accessToken))
      //   })
      //   .catch((err) => {
      //     console.log('err',err);
      //     setLoading(false)
      //     setSubmitting(false)
      //     setStatus('The login detail is incorrect')
      //   })
      // }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      {/* <div>
        <label className='form-label fs-6 fw-bolder text-dark'>Store Location</label>
        <div>
          <select
            className='form-select form-select-solid form-select-lg fw-bold'
            {...formik.getFieldProps('location')}
          >
            <option value=''>Select Location</option>
            {locations?.length > 0 && locations.map((location: any) => (
              <option value={`${location._id}`}>{location.name}</option>
            ))}
          </select>
        </div>
      </div> */}
      {/* <div>
        <label className='form-check form-check-custom form-check-solid form-switch mb-5 justify-content-center'>
          <span className='form-check-label text-muted text margin-right-auto'>User</span>
          <input
            className='form-check-input'
            type='checkbox'
            name='is-admin'
            checked={isAdmin}
            onChange={() =>
              setIsAdmin(!isAdmin)
            }
          />
          <span className='form-check-label text-muted margin-left-auto'>Admin</span>
        </label>
      </div> */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
